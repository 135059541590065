import { Phone } from '@styled-icons/boxicons-regular';
import { Icon, IconProps } from './Icon';

export function PhoneIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Phone />
    </Icon>
  );
}
